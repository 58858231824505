import * as url from '../../params.json';
import ch_logo from "../images/ch-logo.svg";
import mh_logo from "../images/mh-logo.svg";

export const SSO = 'true';
export const SSO_ID = 'sso_id';
export const ROOT_URL = url.ROOT_URL;
export const DP_URL = url.DP_URL;
export const PRODUCT_NAME = 'readabl.ai';
export const ACCESS_TOKEN = "access";
export const REFRESH_TOKEN = "refresh";
export const DOC_ID = "doc_id";
export const DOC_STATUS = "status";
export const USER_ROLE = "user_role";
export const USER_ID = "user_id";
export const USER_NAME = "user_name";
export const LOGOUT = 'logout';
export const SSO_APP = url.SSO_APP;
export const FILTER = 'filter';
export const FROM_DETAILS = 'from_details';
export const SETTINGS = "settings";
export const SECRET_KEY = "rD_fL_dt1s";
export const RP_MONTH = "rp_month";
export const PREV_ROUTE = "prev_route";
export const ENC_DATA = "enc_data";
export const ORDER_DATA = "order_data";
export const GOOGLE_NLP_DOC_ID = "google_nlp_doc_id";
export const CLIENT = "client_id";
export const APP_DATA = "appointment_data";
/*====================================================*/
export const PAGE_LOGIN = "Login";
export const SSO_LANDING = "SSO_Landing";
export const SSO_REDIRECT = "SSO_Redirect";
export const PAGE_DASHBOARD = "Overview";
export const PAGE_DOC_MGMT = "DocManagement";
export const PAGE_USER_MGMT = "UserManagement";
export const PAGE_MANUAL_REVIEW = "ManualReview";
export const PAGE_AUDIT = "Audit";
export const PAGE_AUDIT_REPORT = "AuditReport";
export const PAGE_AUDIT_LOG = "AuditLog";
export const ADMIN_CONFIG = "AdminConfig";
export const PAGE_INFO = "info";
export const LANDING_PAGE = PAGE_DASHBOARD;
export const PAGE_USER_PROFILE = "UserProfile";
export const ALL_URLS = "Urls";
export const PAGE_SUPER_ADMIN = "SuperAdmin";
export const PAGE_DOC_INFO = "DocInfo";
export const PAGE_DOC_STUCK = "DocStuck";
export const PAGE_ACCURACY = "accuracytracker";
export const PAGE_SUBCATEGORY = "subcategory";
export const PAGE_CONFUSION = "confusionmatrix";
export const PAGE_DOC_STUCK_MH = "DocStuckMH";
export const PAGE_REPORTS = "Reports";
export const PAGE_CONFIGURATION = "Configuration";
export const PAGE_GOOGLE_NLP = "MedicalEntityExtraction";
export const SPEECH_RECOGNITION = "SPEECHRECOGNITION";
export const PAGE_VIRTUAL_ASSISTANT = "VirtualAssistant";
export const PAGE_DATA = "Data";
export const PAGE_PATIENT_DETAILS = "PatientDetails";
export const MRN = "mrn";
export const PROMPT = "prompt";

export const PAGE_PATIENT = "Patient";
export const PAGE_ADMIN = "Admin";
export const PAGE_USAGE_REPORT = "UsageReport";
export const PAGE_MONTHLY_USAGE_REPORT = "MonthlyReport";
export const PAGE_CUSTOM_USAGE_REPORT = "CustomReport";
export const PAGE_PERFORMANCE_REPORT = "PerformanceReport";

export const PAGE_URLS = {};
PAGE_URLS[PAGE_LOGIN] = "/";
PAGE_URLS[SSO_LANDING] = "ssoLanding";
PAGE_URLS[SSO_REDIRECT] = "ssoRedirect";
PAGE_URLS[PAGE_DASHBOARD] = "home";
PAGE_URLS[PAGE_DATA] = "data";
PAGE_URLS[PAGE_ADMIN] = "admin";
PAGE_URLS[PAGE_DOC_MGMT] = "docManagement";
PAGE_URLS[PAGE_USER_MGMT] = "userManagement";
PAGE_URLS[PAGE_MANUAL_REVIEW] = "manualReview";
PAGE_URLS[PAGE_AUDIT] = "audit";
PAGE_URLS[PAGE_AUDIT_REPORT] = "auditReport";
PAGE_URLS[PAGE_AUDIT_LOG] = "auditLog";
PAGE_URLS[ADMIN_CONFIG] = "adminConfig";
PAGE_URLS[PAGE_USER_PROFILE] = "userprofile";
PAGE_URLS[PAGE_INFO] = "info";
PAGE_URLS[ALL_URLS] = "urls";
PAGE_URLS[PAGE_SUPER_ADMIN] = "superAdmin";
PAGE_URLS[PAGE_DOC_INFO] = "docInfo";
PAGE_URLS[PAGE_DOC_STUCK] = "docStuck";
PAGE_URLS[PAGE_ACCURACY] = "accuracytracker";
PAGE_URLS[PAGE_SUBCATEGORY] = "subcategory";
PAGE_URLS[PAGE_CONFUSION] = "confusionmatrix";
PAGE_URLS[PAGE_DOC_STUCK_MH] = "docStuckMH";
PAGE_URLS[PAGE_REPORTS] = "reports";
PAGE_URLS[PAGE_USAGE_REPORT] = "usageReport";
PAGE_URLS[PAGE_MONTHLY_USAGE_REPORT] = "monthlyReport";
PAGE_URLS[PAGE_CUSTOM_USAGE_REPORT] = "customReport";
PAGE_URLS[PAGE_PERFORMANCE_REPORT] = "performanceReport";
PAGE_URLS[PAGE_CONFIGURATION] = "configuration";
PAGE_URLS[PAGE_GOOGLE_NLP] = "medicalEntityExtraction";
PAGE_URLS[SPEECH_RECOGNITION] = "speechRecognition";
PAGE_URLS[PAGE_PATIENT] = "patient";
PAGE_URLS[PAGE_VIRTUAL_ASSISTANT] = "virtualAssistant";
PAGE_URLS[PAGE_AUDIT_REPORT] = "auditReport";
PAGE_URLS[PAGE_PATIENT] = "patient";
PAGE_URLS[PAGE_PATIENT_DETAILS] = "patientDetails";

/*===================================================================*/

export const status = {
  queued: "Queued",
  in_progress: "In Progress",
  intervention_rqd: "Intervention Required",
  failed: "Failed",
  processed: "Processed",
  uploading: "Uploading to EHR",
  uploaded: "Uploaded to EHR",
  completing: "Marked for Completion",
  completed: "Completed",
  // deleted:'Deleted'
}

export const CLIENTS = [{ label: "Calvert Health", id: "ch", icon: ch_logo }, { label: "Monument Health", id: "mh", icon: mh_logo }];

export const UI_SCREEN = {
  login: 0,
  dashboard: 1,
  doc_mgmt: 2,
  review: 3,
  audit: 4,
  user_mgmt: 5,
};
export const UI_COMP = {
  document: 1,
  user: 2,
};
export const UI_ACTION = {
  Document_uploaded: 1,
  Document_previewed: 4,
  Document_viewed: 2,
  Document_cover_page_removed: 14,
  Document_updated: 3,
  Document_uploaded_to_onbase: 8,
  Document_completed: 9,
  Document_marked_as_completed: 10,
  // Document_deleted:13,
  // Patient_created: 11,
  User_added: 5,
  User_updated: 6,
  User_deleted: 7,
};
export const IMAGE = {

}

export const MONTH_ARR = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const AUDIT_FILTER_BY = [
  { label: "All", key: "all" },
  { label: "File Name", key: "file_name" },
  { label: "Document Id", key: "doc_id" },
  { label: "MRN", key: "mrn" },
  { label: "Sub Category", key: "sub_category" },
  { label: "Location Name", key: "location_name" }
]