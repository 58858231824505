import axios from "axios";
import * as c from '../constants/constants';
import { getToken } from "../../commonFunctions/getToken";

export default {
  login: function (username, pwd, ip) {
    return axios({
      method: "post",
      url: c.ROOT_URL + "/login",
      auth: {
        username: username,
        password: pwd,
      },
      data: ip
    })
  },
  getAuthToken: function (t_id) {
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getAuthToken",
      auth: {
        username: "t_id",
        password: t_id,
      },
    })
  },
  refresh: function () {
    let refresh_token = localStorage.getItem(c.REFRESH_TOKEN);
    let url = c.SSO && localStorage.getItem(c.SSO_ID) ?
      c.ROOT_URL + "/getTokenRefreshed?t_id=" + localStorage.getItem(c.SSO_ID)
      :
      c.ROOT_URL + "/getTokenRefreshed?t_id=goaBYslwRfubJKDwPISj";
    return axios({
      method: "get",
      url: url,
      headers: { Authorization: "Bearer " + refresh_token },

    })
  },
  general_settings: async function () {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getDocuSettings",
      headers: { Authorization: "Bearer " + auth_token },

    })
  },
  recent_documents: async function (page_limit, page_num) {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getRecentDocs",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  action_items: async function (page_limit, page_num) {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getActionItemsAssigned",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  notifications: async function (page_limit, page_num) {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getNotifications",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  document_status_count: async function () {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getDocsStatusCount",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  document_categories: async function () {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getDocsCategoryCount",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  document_locations: async function () {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getLocationsCount",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },

  document_list: async function (page_number, modal) {
    let auth_token = await getToken();
    var url = "";
    if (modal === 'by_page' && page_number && page_number !== "") {
      url = c.ROOT_URL + "/getDocsList?page_number=" + page_number;
    }
    else if (modal === 'all_records' && page_number && page_number !== "") {
      url = c.ROOT_URL + "/getDocsList?page_limit=" + page_number;
    }
    else url = c.ROOT_URL + "/getDocsList";
    return axios({
      method: "get",
      url: url,
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  document_list_all: async function (page_limit) {
    let auth_token = await getToken();
    var url = "";
    url = c.ROOT_URL + "/getDocsList?page_limit=" + page_limit;
    return axios({
      method: "get",
      url: url,
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  fetch_results: async function (query, page_number, page_limit) {
    let auth_token = await getToken();
    var url = "";
    if (page_number)
      url = c.ROOT_URL + "/getDocsListByFilter?page_number=" + page_number;
    else if (page_limit)
      url = c.ROOT_URL + "/getDocsListByFilter?page_limit=" + page_limit;
    else url = c.ROOT_URL + "/getDocsListByFilter";
    return axios({
      method: "post",
      url: url,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  review_doc: async function (doc_id) {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getDoc/" + doc_id,
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  update_after_review: async function (query, docId, revId, comp, action, str) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/createDocumentRevision/" + docId + "/" + revId + '?comp=' + comp + '&action=' + action + (str === "pm" ? "&force_patient_match=yes" : ''),
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  upload_file: async function (file_array, comp, action) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/uploadDocument?comp=" + comp + '&action=' + action,
      data: file_array,
      headers: {
        "Content-Type": 'multipart/form-data',
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  fetch_results_audit: async function (query, page_number, page_limit) {
    let auth_token = await getToken();
    var url = "";
    url = c.ROOT_URL + "/getAuditLogsByFilter" + (page_number != undefined ? ("?page_number=" + page_number) : "") + (page_limit != undefined ? ("&page_limit=" + page_limit) : "");

    return axios({
      method: "post",
      url: url,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  create_user: async function (userdata, comp, action) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/createUser?comp=" + comp + '&action=' + action,
      data: userdata,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  update_user: async function (userdata, user_id, comp, action) {
    let auth_token = await getToken();
    return axios({
      method: "put",
      url: `${c.ROOT_URL}/updateUser/${user_id}?comp=${comp}&action=${action}`,
      data: userdata,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  update_tags: async function (doc_id, data, rev_id, comp, action) {
    let auth_token = await getToken();
    return axios({
      method: "put",
      url: `${c.ROOT_URL}/updateTags/${doc_id}/${rev_id}?comp=${comp}&action=${action}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  exit_doc_details: async function (doc_id, data) {
    let auth_token = await getToken();
    return axios({
      method: "put",
      url: `${c.ROOT_URL}/updateUserActiveStatus/${doc_id}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  search_suggest_results: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: `${c.ROOT_URL}/getDocsListSuggestions`,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  search_suggest_results_audit: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: `${c.ROOT_URL}/getAuditLogsSuggestions`,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  search_suggest_results_user: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: `${c.ROOT_URL}/getUsersListSuggestions`,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  /*===========ip address public api=============*/
  get_ip: function (doc_id) {
    return axios({
      method: "get",
      url: 'https://api.ipify.org/?format=json'

    });
  },
  all_doc_details: async function (doc_id) {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getDocDetail/" + doc_id,
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  get_doc_id: async function (query, doc_id, step) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getAdjacentDocument/" + doc_id + "/" + step,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  doc_stuck: async function (query, page_limit) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getDocListInProgress?page_limit=" + page_limit,
      data: query,
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  change_doc_status: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "put",
      url: c.ROOT_URL + "/updateDocumentStatus",
      data: query,
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  get_all_flags: async function (str) {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getTagsList" + (str === "config" ? "?configuration=true" : ''),
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  upload_to_onbase: async function (query, comp, action) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/uploadDocToOnBase?comp=" + comp + '&action=' + action,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  complete_document: async function (query, comp, action) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/completeDocument?comp=" + comp + '&action=' + action,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  skip_upload: async function (query, comp, action) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/skipUpload?comp=" + comp + '&action=' + action,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  patient_encounters: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/patientEncounterDetails",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  patient_orders: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/patientOrderDetails",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  order_category: async function () {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getOrderCategory",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  google_nlp: async function (id) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/healthCareApiNlpAnalyzeEntities/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  getAccuracyTracker: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getAccuracyTracker",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  getRpMetrics: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/displayDocumentMetrics",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  avgPageByCategory: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/displayAveragePagesByCategory?page_limit=0",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  avgPageByLocation: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/displayAveragePagesByLocation?page_limit=0",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  rpGraphData: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/displayMonthlyStatistics",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  dailyStatics: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/displayDailyStatistics",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  hourlyLocationStatics: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/displayHourlyStatistics",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  locationStaticsByDate: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/displayDailyStatisticsByLocation",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  hourlyStatisticsByDate: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/displayHourlyStatisticsByDate",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  createPatient: async function (query, doc_id) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.DP_URL + "/createPatientInEpic" + (doc_id ? `?doc_id=${doc_id}` : ""),
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  getUserManagementExport: async function (query, search_string, page_limit) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getUsersList?page_number=0" +
        "&page_limit=" + page_limit +
        "&sort_column=Last Name" +
        "&sort_order=asc" +
        "&search_string=" + search_string,
      data: query,
      headers: { Authorization: "Bearer " + auth_token },
    })
  },
  getPatientLocation: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getPatientLocation",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  createNewFlag: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/addNewTag",
      data: query,
      headers: { Authorization: "Bearer " + auth_token },
    })
  },

  createNewLocation: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/addNewLocation",
      data: query,
      headers: { Authorization: "Bearer " + auth_token },
    })
  },

  getSubCategory: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getSubCategory",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },

  updateFlagDetails: async function (id, query) {
    let auth_token = await getToken();
    return axios({
      method: "patch",
      url: c.ROOT_URL + "/updateTag/" + id,
      data: query,
      headers: { Authorization: "Bearer " + auth_token },
    })
  },
  getAccuracyTracker: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getAccuracyTracker",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  getSubCategory: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getSubCategory",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  getLocationDetails: async function () {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getLocationsList",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  updateLocationDetails: async function (id, query) {
    let auth_token = await getToken();
    return axios({
      method: "patch",
      url: c.ROOT_URL + "/updateLocation/" + id,
      data: query,
      headers: { Authorization: "Bearer " + auth_token },
    })
  },
  getDocStuck: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getDocListInProgress?page_limit=0",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  skip_upload: async function (query, comp, action) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/skipUpload?comp=" + comp + '&action=' + action,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  splitDocument: async function (query, comp, action) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/multiDocumentSplit",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  MedicalTranscription: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/medicalTranscription",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  getUserProfile: async function () {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getUserProfile",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  appointmentList: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/patientAppointmentSearch",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },

  appointmentList: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/patientAppointmentSearch",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  MedicalTranscriptionDetails: async function () {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getMedicalTranscription",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  getOCRText: async function (doc_id) {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getDocumentOCR/" + doc_id,
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  getPatientInfo: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/patientInfo",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },

  getHistoryInfo: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/patientHistory",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },

  getEncounterDetails: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/patientEncounterDetails",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },

  getOrderDetails: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/patientOrderDetails",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },

  getDocumentByID: async function (query, str) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getDocByIdentifier" + (str === "allDocs" ? "?patient_history=true" : ""),
      //  url: c.ROOT_URL + "/getTagsList" + (str === "config" ? "?configuration=true" : ''),
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  getUserProfile: async function () {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getUserProfile",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  getPatientList: async function () {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getPatientList",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  getDocumentCategoryList: async function () {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getDocumentCategoryList",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  getDocClassificationMapping: async function () {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getDocClassificationMapping",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  getVirtualAssisstantResult: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getEmbedResult",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    })
  },
  getAuditLogsForDocument: async function (query, page_number, page_limit) {
    let auth_token = await getToken();
    var url = "";
    url = c.ROOT_URL + "/getAuditLogsForDocument?page_number=" + page_number + "&page_limit=" + page_limit;
    return axios({
      method: "post",
      url: url,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  getAuditLogsForUser: async function (query, page_number, page_limit) {
    let auth_token = await getToken();
    var url = "";
    url = c.ROOT_URL + "/getAuditLogsForUser?page_number=" + page_number + "&page_limit=" + page_limit;
    return axios({
      method: "post",
      url: url,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  getUserPerformance: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getUserPerformanceReport",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  getPatientSuggest: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getPatientSuggestions",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  deleteDocument: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/deleteDocument?comp=1&action=13",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  getPatientsDetails: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getPatientsDetails",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  getPatientNames: async function (page_limit, page_num) {
    let auth_token = await getToken();
    let tempPageLimit = 15
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getPatientNames" + "?page_limit=" + tempPageLimit,
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  getIndividualPerformance: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getIndividualPerformance",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  searchStringFromDocument: async function (query, doc_id) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + `/getDocStringMatch/${doc_id}`,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  removePageFromPDF: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/multiPagesDrop",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  getPatientConditions: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getPatientConditions",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  getPatientProcedures: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getPatientProcedures",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  getPatientDiagnosticReport: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getPatientDiagnosticReportDetails",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  getPatientMedicationDetails: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getPatientMedicationDetails",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  getPatientProblems: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getPatientProblems",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  getPatientList: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getPatientList",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
};
